/**
 * Ergänze CSS-Klasse zum Element `el`. Unterstützung für Internet Explorer 11.
 *
 * @param {HTMLElement} el
 * @param {string} className
 */
function addClass(el, className) {
  if (el.classList) {
    el.classList.add(className);
    return;
  }

  el.setAttribute('class', el.getAttribute('class') + ` ${className}`);
}

/**
 * Entferne CSS-Klasse vom Element `el`. Unterstützung für Internet Explorer 11.
 *
 * @param {HTMLElement} el
 * @param {string} className
 */
function removeClass(el, className) {
  if (el.classList) {
    el.classList.remove(className);
    return;
  }

  el.setAttribute('class', el.getAttribute('class').replace(className, ''));
}

export { addClass, removeClass };
