import './app.scss';
import * as Bsn from 'bootstrap.native/dist/bootstrap-native-v4';
import Velocity from 'velocity-animate/velocity';
import Swiper from 'swiper';
import { Autoplay, Keyboard, Navigation } from 'swiper/modules';
import { LuminousGallery } from 'luminous-lightbox';
import { initSearch } from './js/search';

class KDO {

  constructor() {
    this.cfg = {
      headerBannerSliderId: 'headerBannerSlider',
      contentIconSliderId: 'contentIconSlider',
      contentImageSliderClass: 'image-slider',
      headerBannerSlideClass: 'carousel-item',
      navbarClass: 'navbar',
      navbarCollapseId: 'navbar-header',
      navbarCollapseButtonId: 'collapseButton',
      navbarNavTogglerClass: 'navbar-nav-toggler',
      buttonScrollTopId: 'buttonScrollTop',
      lightboxClass: 'lightbox--item',
      gridBreakpoints: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 1025,
        xl: 1540
      }
    };

    this.init();
  }

  init() {
    this.initPolyfills();
    this.initHeaderBannerSlider();
    this.initNavbar();
    this.initMedia();
    this.initScrollTop();
    this.initScrollToAnchor();
    this.initForm();
    this.initContentIconSlider();
    this.initContentImageSlider();
    this.initHashLinks();
    this.initLightbox();
    this.initStickyContact();
    initSearch();
  }

  initPolyfills() {
    // Support forEach()
    (() => {
      if (typeof NodeList.prototype.forEach === 'undefined') {
        NodeList.prototype.forEach = Array.prototype.forEach;
      }
      if (typeof HTMLCollection.prototype.forEach === 'undefined') {
        HTMLCollection.prototype.forEach = Array.prototype.forEach;
      }
    })();

    // Support CustomEvent
    (() => {
      function CustomEvent(event, params) {
        params = params || {bubbles: false, cancelable: false, detail: undefined};
        let evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
      }

      CustomEvent.prototype = window.Event.prototype;
      window.CustomEvent = CustomEvent;
    })();

    // Support closest()
    (() => {
      if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
      }

      if (!Element.prototype.closest)
        Element.prototype.closest = function (s) {
          let el = this;
          if (!document.documentElement.contains(el)) return null;
          do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
          } while (el !== null && el.nodeType === 1);
          return null;
        };
    })();
  }

  initHeaderBannerSlider() {
    let headerBannerSliderElement = document.getElementById(this.cfg.headerBannerSliderId);

    if (headerBannerSliderElement !== null) {
      new Bsn.Carousel(headerBannerSliderElement, {
        interval: 0
      });

      this.calculateHeaderBannerSlider();
    }
  }

  initContentIconSlider() {
    let contentIconSliderElement = document.getElementById(this.cfg.contentIconSliderId);

    if (contentIconSliderElement !== null) {
      new Swiper('#' + this.cfg.contentIconSliderId, {
        modules: [Autoplay, Keyboard, Navigation],
        slidesPerView: 5,
        spaceBetween: 10,
        loop: true,
        autoplay: {
          delay: 5000
        },
        navigation: {
          nextEl: contentIconSliderElement.parentNode.querySelector('.carousel-control-next'),
          prevEl: contentIconSliderElement.parentNode.querySelector('.carousel-control-prev'),
        },
        keyboard: {
          enabled: true,
          onlyInViewport: true
        },
        breakpoints: {
          1025: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          576: {
            slidesPerView: 1,
          },
        }
      });
    }
  }

  getCurrentPictureSrc(element, cb) {
    let getSrc;
    if(!window.HTMLPictureElement){
      let resp = window.respimage,
        picFill = window.picturefill;
      if(window.resp){
        resp({elements: [element]});
      } else if(picFill){
        picFill({elements: [element]});
      }
      cb(element.src);
      return;
    }

    getSrc = () => {
      element.removeEventListener('load', getSrc);
      element.removeEventListener('error', getSrc);
      cb(element.currentSrc);
    };

    element.addEventListener('load', getSrc);
    element.addEventListener('error', getSrc);

    if(element.complete){
      getSrc();
    }
  }

  setCurrentPictureSrc(elems) {
    let img,
      link;

    elems.forEach(elem => {
      const pictureElements = elem.querySelectorAll('picture'),
        pictures = [].slice.call(pictureElements);

      pictures.forEach(pic => {
        img = pic.getElementsByTagName('img')[0];

        this.getCurrentPictureSrc(img, (src) => {
          link = pic.parentNode.parentNode;

          if(link.style.backgroundImage !== `url("${src}")`) {
            link.style.backgroundImage = `url("${src}")`;
          }
        });
      });
    });
  }

  initContentImageSlider() {
    let contentImageSliderElements = document.querySelectorAll(`.${this.cfg.contentImageSliderClass}`);

    if (contentImageSliderElements !== null) {
      let elems = [].slice.call(contentImageSliderElements);

      elems.forEach(elem => {
        new Swiper(elem, {
          modules: [Autoplay, Keyboard, Navigation],
          slidesPerView: 3,
          spaceBetween: 10,
          loop: true,
          autoplay: {
            delay: 5000
          },
          navigation: {
            nextEl: elem.parentNode.querySelector('.carousel-control-next'),
            prevEl: elem.parentNode.querySelector('.carousel-control-prev'),
          },
          keyboard: {
            enabled: true,
            onlyInViewport: true
          },
          breakpoints: {
            768: {
              slidesPerView: 2,
            },
            576: {
              slidesPerView: 1,
            },
          }
        });
      });

      this.setCurrentPictureSrc(elems);

      window.addEventListener('resize', () => this.setCurrentPictureSrc(elems));
    }
  }

  initLightbox() {

    if(document.querySelectorAll('.' + this.cfg.lightboxClass) !== null) {

      const galleryOpts = {},
        luminousOpts = {};

      new LuminousGallery(document.querySelectorAll('.' + this.cfg.lightboxClass), galleryOpts, luminousOpts);
    }

  }

  initStickyContact() {

    let toggleElement = [...document.querySelectorAll('.stickyContact__item__toggle')];
    let activeClass = 'is-active';

    if (!toggleElement.length) {
      return;
    }

    toggleElement.forEach(toggler => {
      toggler.addEventListener('click', e => {
        e.preventDefault();

        if (toggler.parentNode.classList.contains(activeClass)) {
          toggler.nextElementSibling.classList.remove(activeClass, 'a-block');
          setTimeout(() => toggler.parentNode.classList.remove(activeClass), 500);
        } else {
          toggleElement
            .filter(t => toggler !== t && t.parentNode.classList.contains(activeClass))
            .forEach(t => {
              t.nextElementSibling.classList.remove(activeClass,'a-block');
              setTimeout(() => t.parentNode.classList.remove(activeClass), 500);
            });

          setTimeout(() => toggler.parentNode.classList.add(activeClass), 250);
          setTimeout(() => toggler.nextElementSibling.classList.add('a-block'), 500);
          setTimeout(() => toggler.nextElementSibling.classList.add(activeClass), 520);
        }
      });
    });

  }

  calculateHeaderBannerSlider() {
    const nodeList = document.querySelectorAll('#' + this.cfg.headerBannerSliderId + ' .' + this.cfg.headerBannerSlideClass);
    let elems = [].slice.call(nodeList),
      elemsCalculated = false;

    let calculateHeights = (function calculateHeights(KDO) {
      if (window.innerWidth >= KDO.cfg.gridBreakpoints.lg) {
        elems.forEach(elem => {
          if (!elem.classList.contains('active')) {
            elem.style.position = 'absolute';
            elem.style.visibility = 'hidden';
            elem.style.display = 'block';
          }
        });

        let tallest = Math.max.apply(Math, elems.map(elem => {
          elem.style.minHeight = '';
          return elem.offsetHeight;
        }));

        elems.forEach(elem => {
          elem.style.minHeight = tallest + 'px';
        });

        elems.forEach(elem => {
          if (!elem.classList.contains('active')) {
            elem.style.position = '';
            elem.style.visibility = '';
            elem.style.display = '';
          }
        });

        if (!elemsCalculated) {
          elemsCalculated = true;
        }
      } else {
        if (elemsCalculated) {
          elems.forEach(elem => {
            elem.style.minHeight = '';
          });

          elemsCalculated = false;
        }
      }

      return calculateHeights;
    })(this);

    window.addEventListener('resize', () => calculateHeights(this));
  }

  initNavbar() {
    const navbarElement = document.getElementsByClassName(this.cfg.navbarClass)[0],
      navbarCollapseElement = document.getElementById(this.cfg.navbarCollapseId),
      navbarCollapseButtonElement = document.getElementById(this.cfg.navbarCollapseButtonId),
      navbarCollapseInit = new Bsn.Collapse(navbarCollapseButtonElement),
      navItemElement = document.querySelectorAll('#' + this.cfg.navbarCollapseId + ' .nav-item'),
      navbarNavTogglerElement = document.querySelectorAll('.' + this.cfg.navbarNavTogglerClass),
      scrollOffset = 20;

    let navbarOpen = false;

    navItemElement.forEach((el) => {
      let target = el,
        parent = el.parentNode;

      if (target.classList.contains('trail') || target.classList.contains('active')) {
        if (parent.classList.contains('level_1') && target.querySelector('ul') !== null) {
          target.querySelector('ul').classList.add('show');
          target.querySelector('ul').parentNode.parentNode.classList.add('faded');
          target.querySelector('ul').parentNode.parentNode.classList.add('hide');

          if (target.classList.contains('active')) {
            target.querySelector('ul').classList.add('current');
          }
        } else {
          parent.classList.add('show');
          parent.parentNode.parentNode.classList.add('faded');
          parent.parentNode.parentNode.classList.add('hide');

          if (target.classList.contains('active')) {
            parent.classList.add('current');
          }
        }
      }
    });

    navbarNavTogglerElement.forEach((el) => {
      el.addEventListener('click', (e) => {
        let target = e.currentTarget,
          parent = target.parentNode,
          submenu = parent.getElementsByTagName('ul')[0];

        if (target.classList.contains('back')) {
          parent.classList.remove('show');

          setTimeout(() => {
            parent.classList.add('hide');
          }, 300);

          parent.parentNode.parentNode.classList.remove('faded');
          parent.parentNode.parentNode.classList.remove('hide');

          setTimeout(() => {
            parent.classList.remove('current');
          }, 200);

          parent.parentNode.parentNode.classList.add('current');

        } else {
          submenu.classList.add('show');
          submenu.classList.add('current');
          submenu.classList.remove('hide');

          parent.parentNode.classList.add('faded');

          setTimeout(() => {
            parent.parentNode.classList.remove('current');
          }, 200);

          setTimeout(() => {
            parent.parentNode.classList.add('hide');
          }, 200);
        }

      }, false);
    });

    // set height of nav level_1
    ['scroll', 'resize'].forEach((e) => {
      window.addEventListener(e, () => {
        if (window.innerWidth < this.cfg.gridBreakpoints.lg && navbarOpen) {
          setNavHeight();
        }
      });
    });

    window.addEventListener('resize', (e) => {
      if (e.currentTarget.innerWidth >= this.cfg.gridBreakpoints.lg && navbarOpen) {
        navbarCollapseInit.hide();
      }
    });

    navbarCollapseElement.addEventListener('show.bs.collapse', () => {
      onShow();
    });

    navbarCollapseElement.addEventListener('hide.bs.collapse', () => {
      onClose();
    });

    let onShow = () => {
      setBodyOnShow();
      document.documentElement.classList.add('navbar-open');
      navbarOpen = true;
      navbarElement.classList.add('navbar-nav-show');
      setNavHeight();
    };

    let onClose = () => {
      let top = setBodyOnClose();
      document.documentElement.classList.remove('navbar-open');
      navbarOpen = false;
      navbarElement.classList.remove('navbar-nav-show');
      window.scrollTo(0, top);
      resetNavHeight();
    };

    let setBodyOnShow = () => {
      document.getElementsByTagName('body')[0].style.top = -(window.pageYOffset || document.documentElement.scrollTop) + 'px';
    };

    let setBodyOnClose = () => {
      let top = parseInt(document.getElementsByTagName('body')[0].style.top, 10);
      document.getElementsByTagName('body')[0].style.top = '';
      return Math.abs(top);
    };

    let setNavHeight = () => {
      navbarElement.querySelector('ul.level_1').style.height =
        (
          document.documentElement.clientHeight
          - navbarElement.querySelector('.navbar-brand').offsetHeight
          - (window.innerWidth < this.cfg.gridBreakpoints.md ? 30 : 40)
        ) + 'px';
    };

    let resetNavHeight = () => {
      navbarElement.querySelector('ul.level_1').style.height = '';
    };

    // shrink nav on scroll
    ['load', 'scroll'].forEach((e) => {
      window.addEventListener(e, () => {
        if (window.pageYOffset >= scrollOffset) {
          if (!navbarElement.classList.contains('shrinked') && !navbarOpen) {
            navbarElement.classList.add('shrinked');
          } else {
            return false;
          }
        } else {
          if (navbarElement.classList.contains('shrinked') && !navbarOpen) {
            navbarElement.classList.remove('shrinked');
          } else {
            return false;
          }
        }
      });
    });
  }

  initScrollTop() {
    const buttonScrollTopElement = document.getElementById(this.cfg.buttonScrollTopId);
    let buttonScrollTopReady = true;

    buttonScrollTopElement.addEventListener('click', () => {
      if (buttonScrollTopReady) {
        Velocity(document.getElementsByTagName('html')[0], 'scroll', {
          duration: Math.round(window.pageYOffset / 3),
          begin: () => buttonScrollTopReady = false,
          complete: () => buttonScrollTopReady = true
        });
      }
    });

    // "Nach oben"-Button ein-/ausblenden
    window.addEventListener('scroll', () => this.hideAndShowScrollTopBtn(buttonScrollTopElement));
    this.hideAndShowScrollTopBtn(buttonScrollTopElement);
  }

  hideAndShowScrollTopBtn(btn) {
    if (window.pageYOffset === 0 && !btn.classList.contains('faded')) {
      btn.classList.add('faded');
      return;
    }

    if (window.pageYOffset > 0 && btn.classList.contains('faded')) {
      btn.classList.remove('faded');
    }
  }

  initScrollToAnchor() {
    const nodeList = document.querySelectorAll('[data-link]');
    let elems = [].slice.call(nodeList);

    elems.forEach(elem => {
      elem.addEventListener('click', (e) => {
        e.preventDefault();
        Velocity(document.getElementById(elem.dataset.link),
          'scroll',
          {
            duration: Math.round(document.getElementById(elem.dataset.link).offsetTop / 2),
            offset: -document.getElementsByClassName(this.cfg.navbarClass)[0].clientHeight,
          }
        );
      });
    });
  }

  initMedia() {
    if (document.getElementsByTagName('video') !== null) {
      document.getElementsByTagName('video').forEach((v) => v.autoplay && v.play());
    }
  }

  initForm() {
    if (document.querySelectorAll('[data-reset-form]').length !== 0) {
      const resetList = document.querySelectorAll('[data-reset-form]');
      let resetElems = [].slice.call(resetList);

      resetElems.forEach(elem => {
        elem.addEventListener('click', (e) => {
          e.preventDefault();
          e.currentTarget.closest('form').reset();
        });
      });
    }

    if (document.querySelector('select.form-dependency') !== null) {
      let dependency = document.querySelector('select.form-dependency');

      const handleDependency = (e = dependency) => {
        let target = e.target || e,
          selIndex = target.selectedIndex;

        [...target.options].forEach((el, index) => {
          if (index !== selIndex) {
            [...document.querySelectorAll(`.widget.visible-${index}`)].forEach(elem => elem.style.display = 'none');
            [...document.querySelectorAll(`input.required-${index}`)].forEach(elem => elem.removeAttribute('required'));
            [...document.querySelectorAll(`label.required-${index} .mandatory-symbol`)].forEach(elem => elem.parentNode.removeChild(elem));
          }
        });

        [...document.querySelectorAll(`.widget.visible-${selIndex}`)].forEach(elem => elem.style.display = 'flex');
        [...document.querySelectorAll(`input.required-${selIndex}`)].forEach(elem => elem.setAttribute('required', ''));
        [...document.querySelectorAll(`label.required-${selIndex} .label-colon`)].forEach(elem => elem.insertAdjacentHTML('beforebegin', '<span class="mandatory-symbol mandatory">*</span>'));
      };

      handleDependency();

      dependency.addEventListener('change', (e) => {
        handleDependency(e);
      }, false);
    }

    /*
        Nach Formular-Absendung, Aufklappen der Formular-Dropbox
     */
    if (document.getElementsByClassName('ajaxconfirm')[0] !== undefined) {
      let dropdownLink = document.querySelector('[data-parent="#' + 'contentFormDropdown' + '"]'),
        infotext = document.querySelector('.content-form-dropdown.hide-text-onsubmit .infotext'),
        dropdown = new Bsn.Collapse(dropdownLink);

      infotext.style.display = 'none';
      dropdown.show();
    }
  }

  initHashLinks() {
    let links = document.querySelectorAll('a:not(.valid-hash-link)'),
      _this = this;

    links.forEach((link) => {
      link.addEventListener('click', (e) => {
        if (e.currentTarget.hash && (e.currentTarget.href === location.href || e.currentTarget.href.replace(e.currentTarget.hash, '') === location.href.replace(location.hash, ''))) {
          e.preventDefault();
          handleHashLinks(e.currentTarget, 'onpage');
        }
      });
    });

    let hash, link, offset;

    let handleHashLinks = function handleHashLinks(el, method) {

      hash = el.hash ? el.hash.substr(1) : null;

      if (hash !== null) {
        link = document.querySelector(`[data-id="${hash}"]`);

        if (link !== null) {
          if(link.classList.contains('collapsed')) {
            if (typeof link.click === 'function') {
              link.click();
            } else if (typeof link.onclick === 'function') {
              link.onclick();
            }
          }

          offset = - document.querySelector(`.${_this.cfg.navbarClass} .container`).clientHeight - 20;

          Velocity(link, 'scroll', {
            duration: Math.round(window.pageYOffset / 3),
            delay: method === 'onload' ? 500 : 250,
            offset: offset,
          });
        } else {
          return;
        }
      }

      return handleHashLinks;
    }(location, 'onload');
  }
}

document.addEventListener('DOMContentLoaded', () => new KDO());
