import { removeClass, addClass } from './utils';

function initSearch() {
  const searchOverlay = document.querySelector('.headerSearch__overlay');
  const searchOverlayForm = document.querySelector('.headerSearch__overlay .mod_form');
  const defaultNavigation = document.querySelector('ul.navbar-nav');
  const buttonOpen = document.querySelector('.headerSearch__button.open');
  const buttonClose = document.querySelector('.headerSearch__button.close');
  const buttonCloseSvg = document.querySelector('.headerSearch__button.close svg');
  const classActive = 'is-active';
  const classTransparency = 'has-transparency';

  buttonOpen.addEventListener('click', () => {
    buttonOpen.disabled = true;
    searchOverlay.style.display = 'flex';
    setTimeout(() => buttonClose.classList.add(classActive), 50);
    setTimeout(() => searchOverlayForm.classList.add(classActive), 250);
    setTimeout(() => { addClass(buttonCloseSvg, classActive); defaultNavigation.classList.add(classTransparency); }, 300);
    setTimeout(() => buttonClose.disabled = false, 800);
  });

  buttonClose.addEventListener('click', () => {
    buttonOpen.disabled = true;
    removeClass(buttonCloseSvg, classActive);
    setTimeout(() => searchOverlayForm.classList.remove(classActive), 150);
    setTimeout(() => { defaultNavigation.classList.remove(classTransparency); buttonClose.classList.remove(classActive); }, 500);
    setTimeout(() => { searchOverlay.style.display = 'none'; buttonOpen.disabled = false; }, 900);
  });
}

export { initSearch };
